import React from 'react'
import { Link } from 'gatsby'

import headerStyles from './header.module.scss'

const Header = () => {
    return (
        <header>
            <div className={headerStyles.caixa}>
                <nav>
                    <ul className={headerStyles.navList}>
                        <li>
                            <Link className={headerStyles.navItem} to="/">home</Link>
                        </li>
                        <li>
                            <Link className={headerStyles.navItem} to="/education">education</Link>
                        </li>
                        <li>
                            <Link className={headerStyles.navItem} to="/experience">experience</Link>
                        </li>
                        <li>
                            <Link className={headerStyles.navItem} to="/projects">projects</Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
    )    
}

export default Header